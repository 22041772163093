<template>
  <div class="container">
    <div class="container_block">
      <div class="container_block_top">
        <div class="container_block_title">志愿者查询</div>
        <div class="container_block_content">
          <div class="container_block_content_item">
            <div class="container_block_content_item_title">
              姓名
            </div>
            <div class="container_block_content_item_input">
              <el-input
                style="height:70px;width: 300px;"
                v-model="name"
                :rows="2"
                class="w-50 m-2"
                size="large"
                placeholder="请输入姓名"
              />
            </div>
          </div>

          <div class="container_block_content_item">
            <div class="container_block_content_item_title">
              证件号码
            </div>
            <div class="container_block_content_item_input">
              <el-input
                style="height:70px;width: 300px;"
                v-model="cardnum"
                :rows="4"
                class="w-50 m-4"
                size="large"
                placeholder="请输入证件号码"
              />
            </div>
          </div>

          <div class="container_block_content_item">
            <div class="container_block_content_item_title">
              <el-space
                wrap
                size="large"
              ></el-space>
            </div>
            <div
              class="container_block_content_item_date"
              @click="search"
            >
              查询
            </div>
          </div>

        </div>
      </div>
<!--      v-show="showInfo"-->
      <div
        class="container_block_result"
        v-show="showInfo"
      >
        <div class="container_block_result_head"><img
            :src="data.avatar"
            alt=""
          ></div>
        <div class="container_block_result_info">
          <div class="container_block_result_info_name">{{data?.truename}}</div>
          <div class="container_block_result_info_divider"></div>
          <div class="container_block_result_info_desc">证件号码: <span>{{data?.cardnum ?? "暂无"}}</span></div>
          <div class="container_block_result_info_desc">性别: <span>{{data?.sex==='0' ? "男" : "女"}}</span></div>
          <div class="container_block_result_info_desc">所在站点： <span>{{data?.vlStation?.name}}</span></div>
          <div class="container_block_result_info_desc">服务时长： <span>{{data?.timeLen}}小时</span></div>
          <div class="container_block_result_info_desc">志愿星级:
            <el-rate v-model="data.vipRange" disabled score-template="{data.vipRange}"/>
          </div>
        </div>

        <div class="container_block_result_prof" v-if="profList.length > 0">
          <div class="container_block_result_prof_num">
           获得{{profNum}}个证书
          </div>
          <div
              class="container_block_result_prof_item"
              v-for="(item,index) in profList"
              :key="index"
          >
            <div
                class="container_block_result_prof_item_left"
                @click="changeProfDetail(item.id)"
            >
              <div class="container_block_result_prof_item_left_pic">
                <img
                    src="../../assets/images/do.png"
                    alt=""
                >
              </div>
              <div class="container_block_result_prof_item_left_info">
                <div class="container_block_result_prof_item_left_info_title">{{ item.profName }}</div>
<!--                <div class="container_block_result_prof_item_left_info_profnum">{{ item.profNum }}</div>-->
                <div class="container_block_result_prof_item_left_info_date"> {{ item.createTime }}</div>
              </div>
            </div>
            <div class="container_block_result_prof_item_right">
              <div class="container_block_result_prof_item_right_div">
                <el-divider direction="vertical"></el-divider>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--  证书 dialog  -->
    <el-dialog
        v-model="profDialogVisible"
        title="证书"
        width="500px"
    >
      <div class="profdialog">
        <div class="profdialog_left">
          <div
              class="profdialog_left_bg"
              :style="{backgroundImage: 'url('+profDetail.detail.bgImgUrl+')'}"
          >
            <div class="profdialog_left_bg_stationname">山东阳光志愿者服务平台</div>
            <div class="profdialog_left_bg_title">荣誉证书</div>
            <div class="profdialog_left_bg_username"> {{ profDetail.detail.order1 }}</div>
            <div class="profdialog_left_bg_info">
              {{ profDetail.detail.order2 }}
            </div>
            <div class="profdialog_left_bg_company">{{ profDetail.detail.order3 }}</div>
            <div class="profdialog_left_bg_date"> {{ profDetail.detail.order4 }}</div>
          </div>
          <div class="profdialog_left_num">
            {{ profDetail.detail.order5 }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup name="SearchCenter">
import { ref } from 'vue';
import { getUserByNameAndIdCard } from "@/api/user"
import { ElMessage } from 'element-plus'
import {getCertification} from "@/api/certificate";
const name = ref("")
const cardnum = ref("")
const profNum = ref(0);
const data = ref({ truename: "", cardnum: "", sex: "", vlUserStations: [], vipRange: 0,timeLen:0 })
const showInfo = ref(false)
const profDialogVisible = ref(false)
//证书
const profList = ref([])
const getUser = async () => {
  let result = await getUserByNameAndIdCard({ truename: name.value, cardnum: cardnum.value });
  console.log("result===", result)
  if (result.code === 200) {
    console.log("result===", result.msg)
    data.value = result.data
    profList.value = result.data.profList
    profNum.value = result.data.profNum
    showInfo.value = true
  } else {
    ElMessage.error(result.msg)
    showInfo.value = false
  }
}
//证书
const profDetail = ref({})
const changeProfDetail = async (val) => {
  let result = await getCertification(val);
  profDetail.value = result.data
  profDialogVisible.value = true
  console.error(profDetail.value.detail.bgImgUrl)
}
const search = () => {
  getUser();
}

</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;
  &_block {
    margin: 0 auto;
    width: 65%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    &_top {
      padding: 30px 30px 30px 30px;
      background: #fff;
    }
    &_title {
      font-size: 20px;
      color: #212121;
      font-weight: bold;
      line-height: 20px;
    }
    &_content {
      margin: 50px 100px 60px 150px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &_item {
        margin-right: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_title {
          font-size: 16px;
          color: #212121;
          line-height: 20px;
          height: 20px;
        }
        &_input {
          margin-top: 20px;
        }
        &_date {
          margin-top: 20px;
          width: 300px;
          height: 70px;
          background: url("../../assets/images/searchbtn.png") no-repeat;
          background-size: 100% 100%;
          font-size: 20px;
          color: #3b2f00;
          text-align: center;
          vertical-align: middle;
          line-height: 70px;
        }
      }
    }
    &_result {
      margin: 0 auto;
      border-radius: 6px;
      //background: url("../../assets/images/resultbg.png") no-repeat;
      background-color: rgba(255, 255, 255, 100);
      background-size: 100% 100%;
      margin-top: 30px;
      padding: 60px 60px 60px 60px;
      width: 90%;
      height: 406px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &_head {
      }
      &_head img {
        width: 210px;
        height: 286px;
      }
      &_info {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_name {
          color: #212121;
          font-size: 28px;
          margin-bottom: 10px;
        }
        &_desc {
          line-height: 30px;
          text-align: center;
          margin-top: 30px;
          font-size: 16px;
          color: #212121;
          span {
            font-size: 20px;
            color: #212121;
          }
        }
        &_divider {
          width: 85px;
          height: 6px;
          line-height: 20px;
          background-color: rgba(255, 153, 89, 100);
        }
      }

      &_prof{
        margin-left: 20px;
        flex: 1;
        height: 100%;

        &_num{
          text-align:left;
          padding-left:20px;
          font-size: 16px;
          color: rgba(33, 33, 33, 100);
          font-family: HarmonyOS_Sans_SC-regular;
        }

        &_item {
          background: #fff;
          padding: 20px 20px;
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          border-radius: 6px;

          &_left {
            display: flex;
            flex-direction: row;

            &_pic {
            }

            &_pic img {
              height: 50px;
              margin-right: 10px;
            }

            &_info {
              position: relative;
              text-align: left;

              &_title {
                font-size: 18px;
                color: #212121;
                font-weight: bold;
              }

              &_profnum {
                font-size: 14px;
                color: #505559;
              }

              &_date {
                position: absolute;
                bottom: 0;
                font-size: 14px;
                color: #c2c7cc;
              }
            }
          }

          &_right {
            display: flex;
            flex-direction: row;
            align-items: center;

            &_div {
            }
          }
        }
      }

    }
  }
}
.profdialog {
  display: flex;
  flex-direction: row;
  background: #f5f5f5;

  &_left {
    flex: 1;
    padding: 20px 20px;
    background-color: #fff;

    &_num {
      margin-top: 20px;
    }

    &_bg {
      background-image: url("../../assets/images/profbg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 40px 30px 40px 30px;

      &_stationname {
        color: #424242;
        font-size: 18px;
      }

      &_title {
        color: #ad8427;
        font-size: 72px;
        margin-top: 50px;
        font-weight: bold;
      }

      &_username {
        font-size: 28px;
        color: #212121;
        margin: 50px;
      }

      &_info {
        font-size: 16px;
        color: #212121;
        font-weight: bold;
        text-indent: 20px;
        text-align: left;
      }

      &_profs {
        margin-top: 80px;
        text-align: left;
        font-size: 14px;
        color: #212121;
        font-weight: bold;
      }

      &_company {
        margin-top: 70px;
        color: #757575;
        font-size: 14px;
        text-align: right;
        font-weight: bold;
      }

      &_date {
        margin-top: 13px;
        color: #757575;
        font-size: 14px;
        text-align: right;
      }
    }
  }

  &_left img {
    height: 600px;
  }
}
</style>
