import request from '@/utils/request'
import Qs from 'qs'

export function phonelogin(data){
    return request({
        url: '/appreq/appUser/phoneLogin',
        headers: {
            isToken: false
          },
        method: 'post',
        data
    })
}

export function cardLogin(data){
    return request({
        url: '/appreq/appUser/cardLogin',
        headers: {
            isToken: false,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        method: 'post',
        data: Qs.stringify(data)
    })
}


export function getSmsCode(query){
    return request({
        url: '/appreq/appUser/sendsms',
        headers: {
            isToken: false
          },
        method: 'get',
        params: query
    })
}

export function getUserInfo(){
    return request({
        url: '/appreq/appUser/getUserInfo',
        headers: {
            isToken: true
        },
        method: 'get',
    })
}
export function updateUserInfo(data){
    return request({
        url: '/appreq/appUser/updateUserInfo',
        method: 'post',
        data,
    })
}


export function getUserByNameAndIdCard(data){
    return request({
        url: '/appreq/appUser/getUserByNameAndIdCard',
        headers: {
            isToken: false
        },
        method: 'post',
        data,
    })
}

//获取跳转地址
export function authorize(data) {
    return request({
        url: '/appreq/appUser/authorize?state='+data,
        headers: {
            isToken: false
        },
        method: 'get'
    })
}

//微信登录
export function mpLogin(code,state){
    return request({
        url: '/appreq/appUser/mpLogin?code='+code+'&state='+state,
        headers: {
            isToken: false
        },
        method: 'get'
    })
}

//余额充值
export function pay(data){
    return request({
        url: '/appreq/wxTrade/createOrder',
        headers: {
            isToken: true,
            loading : false
        },
        method: 'post',
        data
    })
}
//查看充值结果
export function checkPay(params){
    return request({
        url: '/appreq/wxTrade/checkPay',
        method: 'get',
        headers: {
            loading : false
        },
        params
    })
}


export function getTeamListNotPage(){
    return request({
        url: '/appreq/team/getTeamListNotPage',
        method: 'get',
    })
}


//申请加入队伍
export function apply(data){
    return request({
        url: '/appreq/userapply/submit',
        method: 'post',
        data
    })
}


//队长申请成为站长
export function applyToStation(data){
    return request({
        url: '/appreq/userapply/applyToStation',
        method: 'post',
        data
    })
}
//根据站点选择队伍
export function getTeamList(query){
    return request({
        url: '/appreq/team/getTeamList',
        headers: {
            loading:false
        },
        method: 'get',
        params: query
    })
}

//获取认证地址
export function getWeChatLogin(){
    return request({
        url: '/appreq/appUser/getWeChatLogin',
        headers: {
            loading:false
        },
        method: 'get'
    })
}

//绑定手机
export function weChatOpenNotify(data){
    return request({
        url: '/appreq/appUser/weChatOpenNotify',
        headers: {
            loading:false
        },
        method: 'get',
        data
    })
}
